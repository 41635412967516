* {
  font-family: Arial;
}

a {
  color: #fec000;
}

.header, 
.thread, 
input, 
p, 
h2,
h3,
.search-params .status span,
.header-data .title,
.plays,
.label {
  color: white;
}

header {
  margin: 30px;
}

header img {
  max-width: 250px;
}

body {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: lightblue;
}

/**** Form ****/

.search-params .user, 
.search-params .min-bgg-rating {
  padding-right: 10px;
  position: relative;
}

.search-params .user input, 
.search-params .min-bgg-rating input {
  width: calc(100% - 25px);
  padding: 20px 0px 10px 0px;
  font-size: 16px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 1px solid #625874;
  outline: none;
  background: transparent;
}

.search-params .user .label, 
.search-params .min-bgg-rating .label{
  position: absolute;
  top:-10px;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  pointer-events: none;
  transition: .5s;
}

.search-params .user input:focus ~ .label,
.search-params .user input:valid ~ .label, 
.search-params .min-bgg-rating input:focus ~ .label,
.search-params .min-bgg-rating input:valid ~ .label {
  top: -30px;
  left: 0;
  color: white;
  font-size: 12px;
}

.button {
  margin-top: 20px;
  display: inline-block;
  padding: .75rem 1.25rem;
  border-radius: 10rem;
  color: black;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-width: 0px;
}
.button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d6d6e5;
    border-radius: 10rem;
    z-index: -2;
}
.button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #00005c;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
}
.button:hover {
  color: #fff;
  cursor: pointer;
}
.button:hover:before {
    width: 100%;
}

.search-params form {
  border-radius: 6px;
  background: #55476F;
  box-shadow: 0px 0px 12px #aaa, -0px -0px 12px #fff;
  padding: 15px;
}

.search-result-wrap, 
.hot-info, 
.forum {
  border-radius: 6px;
  background: #55476F;
  box-shadow: 0px 0px 12px #aaa, -0px -0px 12px #fff;
  padding: 15px 30px;
  margin: auto;
  margin-bottom: 25px;
}

.search-result, 
.search-params {
  padding-left: 50px;
  padding-right: 50px;
}

.search-params .hotness, 
.search-params .forums, 
.search-params form {
  width: 33.33%;
}

.hot-info .header, .forum .header {
  text-align: center;
}

.hot-info .game-data {
  max-width: 288px;
}

/**** Game.js ****/

.game, .thread {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid #625874;
}

.game:last-child, .thread:last-child {
  border:none;
}

.game-header {
  position: relative;
  margin-bottom: 10px;
}

.game-header a {
  text-decoration: none;
}

.image-wrap {
  border-radius: 50%;
  height: 100px;
  overflow: hidden;
  width: 100px;
}

.image-game {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.red-game {
  background-color: #b2151f;
}
.blue-game {
  background-color: #5369a2;
}
.green-game {
    background-color: #1d804c;
}
.grey-game {
  background-color: #666e75;
}

.rating {
  border-radius: 10px;
  color: white;
  font-size: 20px;
  padding: 10px;
  margin-left: 20px;
  min-width: 30px;
}

.game-data {
  max-width: 400px;
  padding-left: 20px;
}

h1.title-game {
  display: block;
  margin-right: 10px;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0px;
}

.year-game {
  font-size: 14px;
  display: block;
  margin: 0px;
  margin-top: 10px;
}

.game-info {
  margin-left: 20px;
  min-width: 200px
}

.game-info .number-players, 
.game-info .duration {
  font-size: 14px;
}

.thread-data {
  width: 100%;
}

.thread .thread-subject a {
  text-decoration: none;
}

.thread .thread-subject,
.thread .thread-author,
.thread .thread-messages {
  position: relative;
  padding-left: 30px;
  margin: 10px 0px;
  font-size: 14px;
}

.thread .thread-subject a:before,
.thread .thread-author:before,
.thread .thread-messages:before {
  width: 19px;
  height:19px;
  content: "";
  background-size:100%;
  position: absolute;
  left: 0px;
  color: white;
}

.thread .thread-subject a:before {
  background-image: url(../../media/thread.png);
}

.thread .thread-author:before {
  background-image: url(../../media/author.png);
}

.thread .thread-messages:before {
  background-image: url(../../media/messages.png);
}

.details {
  margin: 30px;
  padding: 20px;
  border-radius: 6px;
  background: #55476F;
  box-shadow: 0px 0px 12px #aaa, -0px -0px 12px #fff;
}

.details .image {
  max-width: 300px;
  max-height: 300px;
}

.details .header-data {
  padding-left: 25px;
}

.details .header-data h2 {
  font-size: 18px;
  margin-top: 0px;
  display: inline-block;
}

.details .designer,
.details .artist,
.details .publisher,
.details .category,
.details .mechanic {
  padding-left: 10px;
  display: inline-block;
  margin: 0;
  margin-bottom: 10px;
}

.details .header-designers,
.details .header-artists,
.details .header-publishers,
.details .header-categories,
.details .header-mechanics {
  margin-bottom: 10px;
}

.details .section {
  border-bottom: 2px solid whitesmoke;
  padding-bottom: 5px;
}

.details .description {
  line-height: 1.5;
  margin-bottom: 30px;
}

.play:nth-of-type(odd) {
  background-color: #887E9A;
  border-bottom: 2px solid #625874;
}

.play:nth-of-type(even) {
  background-color:#9991A9;
  border-bottom: 2px solid #625874;
}

.play:last-child {
  border: 0;
}

.play {
  padding: 10px 0px;
  min-height: 50px;
}

.play .index {
  padding-left: 25px;
  padding-right: 20px;
  min-width: 35px;
  justify-content: center;
}

.play .players {
  padding-left: 25px;
}

.play .no-players {
  padding-left: 10px;
}

.play .player {
  margin-right: 25px;
}

.play .name {
  padding-left: 10px;
}

.win, .lose, .no-players-image {
  width: 25px;
}

@media (max-width: 600px) {

  h1.title-game {
    overflow: visible;
    white-space: normal;
  }

  .year-game {
    margin: 10px 0px;
  }

  .search-result-wrap {
    padding: 15px 20px;
  }

  .search-result, 
  .search-params {
    padding-left: 25px;
    padding-right: 25px;
  }

  .search-params form {
    width: calc(100% - 30px);
    margin-bottom: 30px;
    }
  
    .search-params .hotness, 
    .search-params .forums {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .status {
      margin-bottom: 20px;
    }
  
    .status label {
      margin-right: 20px;
      display: block;
      margin-bottom: 10px;
    }

    .status label span {
      margin-left: 10px;
    }
  
    .game a {
      display: block;
    }
  
    .game .rating {
      display: block;
      width: 30px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      text-align: center;
    }

    .game-header {
      margin-bottom: 0;
    }
  
    .game-data {
      max-width: 270px;
      margin-top: 10px;
      padding-left: 0px;
      text-align: center;
    }

    .game-info {
      text-align: center;
      margin-left: 0px;
    }
  
    .game-info div {
      position: relative;
      margin-left: 0px;
      display: inline-block;
      text-align: left;
    }

    .game-info div:first-child {
      width: 45%;
    }

    .game-info div:last-child {
      width: 55%;
    }

    .game-info div+div {
      text-align: right;
    }

    .game-info span {
      display: none;
    }

    .game-info .number-players {
      padding-left: 30px;
    }

    .game-info .duration {
      padding-right: 30px;
    }

    .game-info div:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      width: 22px;
      height: 22px;
      background-size:100%;
    }

    .game-info div:first-child:before {      
      left: 0;
      background-image: url(../../media/author.png);
    }

    .game-info div:last-child:before {
      right: 0;
      background-image: url(../../media/rellotge.png);
    }

    .game .image-wrap {
      margin-right: auto;
      margin-left: auto;
    }

    .details .header-data {
      padding-left: 0;
    }

    .details-header .image {
      margin-left: 50%;
      transform: translateX(-50%);
    }
    
    .play .name,
    .play .lose, 
    .play .no-players-image, 
    .play .win {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 10px;
    }

    .play .date {
      text-align: center;
      margin-bottom: 10px;
    }

    .play .index {
      display: none;
    }
}

@media (min-width: 600px) and (max-width: 768px) {

  .search-result, .search-params {
    padding-left: 25px;
    padding-right: 25px;
  }

  .search-params form {
  width: calc(100% - 30px);
  margin-bottom: 30px;
  }

  .search-params .hotness, 
  .search-params .forums {
    width: 100%;
    margin-bottom: 30px;
  }

  .status {
    margin-bottom: 20px;
  }

  .status label {
    margin-right: 20px;
  }

  .game a {
    display: inline-block;
    vertical-align: middle;
  }

  .game .rating {
    display: inline-block;
  }

  .game-data {
    max-width: 270px;
  }

  .game-info {
    margin-left: 0px;
  }


}

@media (min-width: 768px) {
  header {
    display: flex;
  }

  .search-params .status {
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .search-params .status p {
    margin-bottom: 5px;
  }
  .search-params .status label {
    margin: 5px 0px;
  }
  .search-params .status input {
    position: absolute;
    left: -9999px;
  }
  .search-params .status input:checked + span {
    background-color: #d6d6e5;
  }
  .search-params .status input:checked + span:before {
    box-shadow: inset 0 0 0 0.2875em #00005c;
  }
  .search-params .status span {
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    font-size: 16px;
  }
  .search-params .status span:hover {
    background-color: #d6d6e5;
    color: black;
  }
  .search-params .status span:before {
    content: "";
    background-color: #fff;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #00005c;
  }

  .search-params-wrap {
    width: 100%;
    display: flex;
    column-gap: 25px;
    align-items: flex-start;
  }

  .search-params .status {
    display: flex;
    flex-direction: column;
  }

  .search-params .status label {
    display: flex;
  }

  .search-params .status span {
    display: flex;
    align-items: center;
  }

  .search-params .status span:before {
    display: flex;
    flex-shrink: 0;
  }

  .game, .thread {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .thread {
    flex-direction: column;
    align-items: flex-start;
  }

  .game-header {
    display: flex;
    align-items: center;
  }

  .game-header a {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .image-wrap {
    display: flex;
    align-items: center;
  }

  .rating {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .game-data {
    display: flex;
    flex-direction: column;
  }

  .game-info {
    display: flex;
    flex-direction: column;
  }

  .thread-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .thread .thread-subject,
  .thread .thread-author,
  .thread .thread-messages {
    display: flex;
    align-items: center;
  }

  .details-header {
    display: flex;
    flex-direction: row;
  }

  .details .header-designers,
  .details .header-artists,
  .details .header-publishers,
  .details .header-categories,
  .details .header-mechanics {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .plays div {
    display: flex;
  }

  .play {
    align-items: center;
  }

  .play .players {
    flex-direction: row;
  }

  .play .no-players {
    align-items: center;
  }

  .play .player {
    flex-direction: row;
    align-items: center;
  }

}
